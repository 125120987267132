<template>
    <div>
        <div class="comLogin short-container">
            <Breadcrumb :breadcrumb="state.listBreadcrumb" />
            <div class="comLogin__container">
                <p class="comLogin__container-sub_title">
                    認証キーが間違っているか失効しています
                </p>
                <Form class="horizontal">
                    <div class="comLogin__container-button">
                        <button
                            type="button"
                            @click="gotoOrganizationLogin"
                            class="btn-lg sweep-to-top"
                        >
                            ログイン画面へ
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/reset-password-expired/OrganizationResetPasswordExpired.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/reset-password-expired/OrganizationResetPasswordExpired.scss";
</style>
